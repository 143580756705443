<script>
import { mapGetters } from "vuex";

var CryptoJS = require("crypto-js");

export default {
  data() {
    return {};
  },
  methods: {
    $decryptPassword(password) {
      if (password) {
        var CryptoJSAesJson = {
          stringify: function(cipherParams) {
            var j = {
              ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
            };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
          },
          parse: function(jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({
              ciphertext: CryptoJS.enc.Base64.parse(j.ct),
            });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
          },
        };

        var decrypted = JSON.parse(
          CryptoJS.AES.decrypt(password, "123456", {
            format: CryptoJSAesJson,
          }).toString(CryptoJS.enc.Utf8)
        );

        return decrypted;
      }
    },
    $encryptPassword(password) {
      if (password) {
        var CryptoJSAesJson = {
          stringify: function(cipherParams) {
            var j = {
              ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
            };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
          },
          parse: function(jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({
              ciphertext: CryptoJS.enc.Base64.parse(j.ct),
            });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
          },
        };
        var key = "123456";

        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(password), key, {
          format: CryptoJSAesJson,
        }).toString();

        return encrypted;
      }
    },
  },
};
</script>
