export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/admin',
    name: 'auth-admin',
    component: () => import('@/views/Admin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/inaccessable-ip-adress',
    name: 'inaccessable-ip-adress',
    component: () => import('@/views/error/InAccessableIpAdress.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => {
      //   store.commit("app/CLEAR_TOKEN");
      //  localStorage.removeItem("token");
      //  localStorage.removeItem("expirationDate");
      //  router.push({ name: "login" });
    },
  },
];
