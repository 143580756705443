export default [
    {
      path: '/security-creation',
      name: 'security-creation',
      component: () => import('@/views/security-creation/SecurityCreation.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];