<script>
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios";

export default {
  data() {
    return {};
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    validPermissions: {
      get() {
        return [1,2,3,4]
        // return this.$store.getters["validPermissions"];
      },
    },
  },
  methods: {
    $checkAccessRight(entryType, actionType) {
      
      if (this.validPermissions.length > 0) {
        let accessLevel = null;

        this.validPermissions.forEach((perm) => {
          if (this.user.role == perm.TeamName) {
            accessLevel = perm.AccessLevel;
          }
        });

        let checkData = {
          auth: this.user,
          accessLevel: accessLevel != null ? accessLevel : '4',
          entryType: entryType,
          actionType: actionType,
        };
        
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`checkAccessRight`, checkData)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },
  },
};
</script>
