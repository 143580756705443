export default [
  {
    path: "/user/user-list",
    name: "user-list",
    component: () => import("@/views/user/user-list/UserList.vue"),
    meta: {
      resource: "users",
      action: "read",
    },
  },

  {
    path: "/user/user-edit",
    name: "user-edit",
    component: () => import("@/views/user/user-add/UserAdd.vue"),
    meta: {
      resource: "users",
      action: "write",
    },
  },

  {
    path: "/user/user-add",
    name: "user-add",
    component: () => import("@/views/user/user-add/UserAdd.vue"),
    meta: {
      resource: "users",
      action: "create",
    },
  },

  {
    path: '/user/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user/user-profile/AccountSetting.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
