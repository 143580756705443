export default [
    {
      path: '/journals',
      name: 'journals',
      component: () => import('@/views/journals/Journals.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },

    {
      path: '/journals/selected-account-names/:accountName',
      name: 'journal-selected-account-names',
      component: () => import('@/views/journals/journal-codes/SelectedAccountNames.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },

    {
      path: '/journals/selected-account-codes/:accountCode',
      name: 'journal-selected-account-codes',
      component: () => import('@/views/journals/journal-codes/SelectedAccountCodes.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];