export default [
    {
      path: '/fx-options',
      name: 'fx-options',
      component: () => import('@/views/fx-options/FXOptions.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];