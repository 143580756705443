export default [
    {
      path: '/equities',
      name: 'equities',
      component: () => import('@/views/equity/Equities.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/equity/amend-details/:id',
      name: 'equity-amend-details',
      component: () => import('@/views/equity/amendDetails/AmendDetails.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];
  