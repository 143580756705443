import Vue from 'vue';
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import {IconsPlugin, BootstrapVueIcons} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
/*import '@/libs/acl';*/
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';

//Permissions mixin
import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);

// Bcrypt password mixin
import BcryptPassword from './mixins/BcryptPassword';
Vue.mixin(BcryptPassword);

// Access Right Permission
import AccessRight from './mixins/AccessRight';
Vue.mixin(AccessRight);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
