export default [
    {
      path: '/FX-spot&forward&swap',
      name: 'fxsfs',
      component: () => import('@/views/fx spot-forward-swap/FXSpotForwardSwap.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },

    {
      path: '/fxExecuted/amend-details/:id',
      name: 'fx-amend-details',
      component: () => import('@/views/fx spot-forward-swap/amendDetails/AmendDetails.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];