export default [
    {
      path: '/transfers',
      name: 'transfers',
      component: () => import('@/views/transfers/Transfers.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },

    {
      path: '/cashTransactions/amend-details/:id',
      name: 'transfer-amend-details',
      component: () => import('@/views/transfers/amendDetails/AmendDetails.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];