import Vue from "vue";
import Vuex from "vuex";
import axiosIns from "@/libs/axios";

// Modules

import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      name: null,
      password: null,
      role: null,
    },
    localeLanguage: {
      locale: "en",
      img: require("@/assets/images/flags/en.png"),
      name: "English",
    },
    validPermissions: null,
    runLastEquityBondSearchData: [],
    runLastEquitySearchData: [],
    runLastBondSearchData: [],
    runLastFxSpotForwardSwapSearchData:[],
    runLastTransfersSearchData:[],
    runLastFxOptionsSearchData:[],
    runLastJournalSearchData:[],
    lastEquityBondSearchData:[],
    usersOrderColumnsEquityData:[],
    usersOrderColumnsBondData:[],
    usersOrderColumnsTransferData:[],
    usersOrderColumnsFxData:[]
  },
  getters: {
    authUser: (state) => {
      return state.user;
    },
    getLanguage: (state) => {
      return state.localeLanguage;
    },
    validPermissions: (state) => {
      return state.validPermissions;
    },
    getLastSearchEquityBond: (state) => {
      let searchUser = state.runLastEquityBondSearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getLastSearchEquity: (state) => {
      let searchUser = state.runLastEquitySearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getLastSearchBond: (state) => {
      let searchUser = state.runLastBondSearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getLastSearchFxSpotForwardSwap: (state) => {
      let searchUser = state.runLastFxSpotForwardSwapSearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getLastSearchTransfers: (state) => {
      let searchUser = state.runLastTransfersSearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getLastSearchJournal: (state) => {
      let searchUser = state.runLastJournalSearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getLastSearchFxOptions: (state) => {
      let searchUser = state.runLastFxOptionsSearchData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.searchData;
    },
    getUserOrderColumnsEquity: (state) => {
      let searchUser = state.usersOrderColumnsEquityData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsBond: (state) => {
      let searchUser = state.usersOrderColumnsBondData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsTransfer: (state) => {
      let searchUser = state.usersOrderColumnsTransferData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsFx: (state) => {
      let searchUser = state.usersOrderColumnsFxData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
  },
  mutations: {
    SET_USER(state, user) {

      // console.log(typeof state.runLastEquityBondSearchData)
      //   if (typeof state.runLastEquityBondSearchData == "object" || state.runLastEquityBondSearchData.length == 0) {
      //     state.runLastEquityBondSearchData = [];
      //   }
      
      state.user = user;
    },
    SET_LANGUAGE(state, lang) {
      state.localeLanguage = lang;
    },
    _setValidPermission(state, perm) {
      state.validPermissions = perm;
    },
    _saveLastSearchEquityBond(state, searchData) {
      if (state.runLastEquityBondSearchData.length > 0) {
        let searchUser = state.runLastEquityBondSearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastEquityBondSearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastEquityBondSearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }

      //state.runLastEquityBondSearchData = searchData
    },
    _saveLastSearchEquity(state, searchData) {
      if (state.runLastEquitySearchData.length > 0) {
        let searchUser = state.runLastEquitySearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastEquitySearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastEquitySearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }

      //state.runLastEquityBondSearchData = searchData
    },
    _saveLastSearchBond(state, searchData) {
      if (state.runLastBondSearchData.length > 0) {
        let searchUser = state.runLastBondSearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastBondSearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastBondSearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }

      //state.runLastEquityBondSearchData = searchData
    },
    _saveLastSearchFxForwardSwap(state, searchData) {
      if (state.runLastFxSpotForwardSwapSearchData.length > 0) {
        let searchUser = state.runLastFxSpotForwardSwapSearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastFxSpotForwardSwapSearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastFxSpotForwardSwapSearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }
    },
    _saveLastSearchTransfers(state, searchData) {
      if (state.runLastTransfersSearchData.length > 0) {
        let searchUser = state.runLastTransfersSearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastTransfersSearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastTransfersSearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }
    },

    _saveLastSearchJournals(state,searchData) {
      if (state.runLastJournalSearchData.length > 0) {
        let searchUser = state.runLastJournalSearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastJournalSearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastJournalSearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }
    },
    _saveLastSearchFxOption(state,searchData) {
      if (state.runLastFxOptionsSearchData.length > 0) {
        let searchUser = state.runLastFxOptionsSearchData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.searchData = searchData;
        } else {
          state.runLastFxOptionsSearchData.push({
            user: state.user.name,
            searchData: searchData,
          });
        }
      } else {
        state.runLastFxOptionsSearchData.push({
          user: state.user.name,
          searchData: searchData,
        });
      }
    },
    _saveOrderColumnsEquity(state,columnsData){
      if (state.usersOrderColumnsEquityData.length > 0) {
        let searchUser = state.usersOrderColumnsEquityData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsEquityData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsEquityData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsBond(state,columnsData){
      if (state.usersOrderColumnsBondData.length > 0) {
        let searchUser = state.usersOrderColumnsBondData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsBondData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsBondData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsTransfer(state,columnsData){
      if (state.usersOrderColumnsTransferData.length > 0) {
        let searchUser = state.usersOrderColumnsTransferData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsTransferData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsTransferData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsFx(state,columnsData){
      if (state.usersOrderColumnsFxData.length > 0) {
        let searchUser = state.usersOrderColumnsFxData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsFxData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsFxData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    }
  },
  actions: {
    setUser({ commit }, newValue) {
      commit("SET_USER", newValue);
    },
    setLanguage({ commit }, newValue) {
      commit("SET_LANGUAGE", newValue);
    },
    setValidPermission({ commit }, newValue) {
      commit("_setValidPermission", newValue);
    },
    getTeamNames(context, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("getTeamNames", user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getUserNames(context) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getUserNames")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveLastSearchEquityBond({ commit }, searchData) {
      commit("_saveLastSearchEquityBond", searchData);
    },
    saveLastSearchBond({ commit }, searchData) {
      commit("_saveLastSearchBond", searchData);
    },
    saveLastSearchEquity({ commit }, searchData) {
      commit("_saveLastSearchEquity", searchData);
    },
    saveLastSearchFxForwardSwap({ commit }, searchData) {
      commit("_saveLastSearchFxForwardSwap", searchData);
    },
    saveLastSearchTransfers({ commit }, searchData) {
      commit("_saveLastSearchTransfers", searchData);
    },
    saveLastSearchJournals({ commit }, searchData) {
      commit("_saveLastSearchJournals", searchData);
    },
    saveLastSearchFxOption({ commit }, searchData) {
      commit("_saveLastSearchFxOption", searchData);
    },
    saveOrderColumnsEquity({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsEquity`,{columnsData:columnsData, type: "Equity"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsEquity", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsEquity({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsEquity`,{type: "Equity"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsEquity", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },

    saveOrderColumnsBond({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsBond`,{columnsData:columnsData, type: "Bond"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsBond", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsBond({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsBond`,{type: "Bond"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsBond", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },
    saveOrderColumnsTransfer({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsTransfer`,{columnsData:columnsData, type: "Transfer"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsTransfer", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsTransfer({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsTransfer`,{type: "Transfer"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsTransfer", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },
    saveOrderColumnsFx({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsFx`,{columnsData:columnsData, type: "FxExecuted"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsFx", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsFx({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsFx`,{type: "FxExecuted"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsFx", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    }
  },

  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
});
