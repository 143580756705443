export default [
    {
      path: '/account-creation',
      name: 'account-creation',
      component: () => import('@/views/account-creation/AccountCreation.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/account-creation/all-account-codes',
      name: 'allAccountCodes',
      component: () => import('@/views/account-creation/account-codes/AllAccountCodes.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/account-creation/selected-account-codes/:id',
      name: 'selectedAccountCodes',
      component: () => import('@/views/account-creation/account-codes/SelectedAccountCodes.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];